import React from "react";
import {
    ImageBackground,
    StyleSheet,
    Text,
    View,
} from "react-native";
import { useMediaQuery } from "./hooks/media";


const Box = (props) => {
    const {
        blurRadius,
        contentPosition,
        overlayAlpha,
        overlayColor,
        source,
        title,
        titleStyle,
        padding,
        clickHandler,
        columns
    } = props;

    const bigScreen = useMediaQuery('(min-width: 992px)');
    const mediumScreen = useMediaQuery('(min-width: 480px)') && !bigScreen;

    const styles = StyleSheet.create({
        title: {
            position: 'absolute',
            bottom: 0,
            margin: 20,
            color: "white",
            textAlign: "center",
            fontSize: 16
        },
        imageGridItem: {
            display: 'inline-block',
            boxSizing: 'border-box',
            float: 'left',
            width: mediumScreen ? `${Math.floor(100 / columns[1])}%` : (bigScreen ? `${Math.floor(100 / columns[2])}%` : `${Math.floor(100 / columns[0])}%`),
            padding,
        },
        imageWrapper: {
            position: 'relative',
            width: '100%',
            paddingBottom: '100%',
            backgroundImage: `url(${source.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            height: '100%'
        },
    });

    let justifyContent;
    if (contentPosition === "top") {
        justifyContent = "flex-start";
    } else if (contentPosition === "bottom") {
        justifyContent = "flex-end";
    } else if (contentPosition === "center") {
        justifyContent = "center";
    }
        
    return (
        <View className="imageGridItem" style={styles.imageGridItem}>
            <a onClick={clickHandler ? clickHandler.bind(this, source.url) : null}>
                <ImageBackground
                    className="backgroundImage"
                    source={source}
                    style={[
                        styles.imageWrapper,
                        {
                            justifyContent: justifyContent,
                            overflow: "hidden",
                            alignItems: "center"
                        }
                    ]}
                    blurRadius={blurRadius}
                    >
                    <View
                        style={{
                            ...StyleSheet.absoluteFillObject,
                            backgroundColor: overlayColor,
                            opacity: overlayAlpha
                        }}
                        />
                    <Text style={[styles.title, titleStyle]}>{title}</Text>
                </ImageBackground>
            </a>
        </View>
    );
}
    
Box.defaultProps = {
    overlayColor: "#000000",
    overlayAlpha: 0.5,
    contentPosition: "center",
    blurRadius: 0,
};

export default Box;