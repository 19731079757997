import React, { Component } from "react"
import { View } from "react-native"
import { Player, Video as VideoHTML, DefaultUi } from '@vime/react';
import "./Video.css"


const S3_URL = "https://storage.yandexcloud.net"
const S3_BUCKET_NAME = "shb"
const S3_VIDEO_FOLDER_NAME = "videos-e2f092330e0db17c3ef1"

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoId: this.videoId()
        }
    }

    videoId() {
        return (
            window.location.hash ? window.location.hash.substring(1) : ""
        )
    }

    filename(videoid) {
        let filename = `${videoid}.mp4`
        this.props.config.forEach((media)=>{
            if (media.type === 'video' && media.id === videoid && media.filename != "") {
                filename = media.filename
            }
        })
        return filename
    }

    videoURL(videoid) {
        return `${S3_URL}/${S3_BUCKET_NAME}/${S3_VIDEO_FOLDER_NAME}/${this.filename(videoid)}.mp4`
    }
    
    timecodes(videoid) {
        let timeCodes = []
        let idx = 0;
        this.props.config.forEach((media) => {
            if (media.type === 'video' && media.id === videoid && media.timecodes !== undefined) {
                media.timecodes.forEach((timecode)=>{
                    timeCodes.push(<p key={videoid+'-'+idx.toString()} >{timecode}</p>)
                    idx++
                });
                return
            }
        })
        return timeCodes
    }

    render() {
        return(
            <View
                style={{
                    alignItems: 'center',
                    backgroundColor: 'black'
                }}
            >
                <Player
                    style={{
                        width: '100%',
                        maxWidth: '720px',
                    }}
                >
                    <VideoHTML
                        playbackRates={1, 1.25, 1.5, 2}
                    >
                        <source
                            data-src={this.videoURL(this.state.videoId)}
                            type="video/mp4"
                        />
                    </VideoHTML>
                    <DefaultUi/>
                </Player>
                <div 
                    className="btn"
                    onClick={() => { window.location.href = `${this.videoURL(this.state.videoId)}?response-content-disposition=attachment`}}
                >
                    <span className="me">скачать</span>
                </div>
                <div className="timecodes">
                    {this.timecodes(this.state.videoId)}
                </div>
            </View>
        )
    }
}

export default Video;