import React from 'react';
import Box from './Box';
import PropTypes from 'prop-types';

const Grid = ({ mediaArray, padding = 10, columns = [1, 2, 3] }) => {

    const getVideoBox = (media, index) => {
        return (
            <Box
                key={index}
                source={{ uri: media.url }}
                title={media.title}
                titleStyle={{ color: '#FFCD42', fontWeight: 'bold', fontFamily: 'Roboto, sanf-serif' }}
                contentPosition="bottom"
                padding={padding}
                clickHandler={media.clickHandler}
                columns={columns}
            />
        );
    }
    
    const getTextBox = (media, index) => {
        return (
            <Box
                key={index}
                source={{ uri: media.url }}
                title={media.title}
                titleStyle={{
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto, sanf-serif',
                    display: 'inline',
                    lineHeight: '5vh',
                    textAlign: 'justify',
                    fontSize: 'large'
                }}
                overlayColor='#FFCD42'
                overlayAlpha={1.0}
                contentPosition="bottom"
                padding={padding}
                clickHandler={media.clickHandler}
                columns={columns}
            />
        );
    }

    const getImageBox = (media, index) => {
        if (media.thumbnail === undefined) {
            return
        }

        const textAlpha = media.title ? 0.5 : 0.0
        const textStyle = media.title ? {
            color: '#FFCD42'
        } : {}

        return (
            <Box
                key={index}
                source={{ uri: media.thumbnail }}
                title={media.title}
                titleStyle={[
                    {
                        color: 'black',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sanf-serif',
                        display: 'inline',
                        textAlign: 'centser',
                        fontSize: 'large'
                    },
                    textStyle
                ]}
                contentPosition="bottom"
                padding={padding}
                clickHandler={media.clickHandler}
                columns={columns}
                overlayAlpha={textAlpha}
            />

        );
    }

    const getBox = (media, index) => {
        if (media.type === "video") {
            return getVideoBox(media, index)
        } else if (media.type === "text") {
            return getTextBox(media, index)
        } else if (media.type === "image") {
            return getImageBox(media, index)
        }
    }
    
    const imageNodes = mediaArray.map((media, index) => {
        return getBox(media, index)
    });

    return (
        <div className="grid">
            {imageNodes}
        </div>
    );
};

Grid.propTypes = {
    mediaArray: PropTypes.array.isRequired,
    columns: PropTypes.array,
    padding: PropTypes.number,
};

Grid.defaultProps = {
    mediaArray: [],
    columns: [1, 2, 3],
    padding: 0,
};

export default Grid;