import React, { Component } from "react";
import { View } from "react-native";
import Grid from './Grid'
import { withRouter } from 'react-router-dom'

const config = require('../config.json')

const S3_URL = "https://storage.yandexcloud.net"
const S3_BUCKET_NAME = "shb"
const S3_VIDEO_FOLDER_NAME = "videos-e2f092330e0db17c3ef1"

class Gallery extends Component {
    constructor(props) {
        super(props);
    }

    filename(videoid) {
        let filename = `${videoid}.mp4`
        config.forEach((media) => {
            if (media.type === 'video' && media.id === videoid && media.filename != "") {
                filename = media.filename
            }
        })
        return filename
    }

    getVideoThumbnail(videoid) {
        return `${S3_URL}/${S3_BUCKET_NAME}/${S3_VIDEO_FOLDER_NAME}/${this.filename(videoid)}.png`
    }

    getMedia() {
        var result = [];
        this.props.config.forEach(media => {
            if (media.type === "video") {
                result.push(
                    { 
                        type: "video",
                        url: this.getVideoThumbnail(media.id),
                        title: media.title,
                        clickHandler: (url, obj) => { this.props.history.push(`/video#${media.id}`); }
                    }
                )
            } else if (media.type === "text") {
                result.push(
                    {
                        type: "text",
                        title: media.title,
                        clickHandler: (url, obj) => { 
                            if (media.url) {
                                window.open(media.url, "_blank");
                            }   
                        }
                    }
                )
            } else if (media.type === "image") {
                result.push(
                    {
                        type: "image",
                        title: media.title,
                        thumbnail: media.thumbnail,
                        clickHandler: (url, obj) => {
                            if (media.url) {
                                window.open(media.url, "_blank");
                            }
                        }
                    }
                )
            }
        });        
        return result
    }

    render() {

        return (
            <View
                style={{
                    marginTop: -5,
                    backgroundColor: 'black',
                    flex: 1,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
                >
                <Grid mediaArray={this.getMedia()} columns={[1, 3, 5]} padding={10} />        
            </View>
        )
    }
}

export default withRouter(Gallery);