import './App.css';
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Gallery from './views/Gallery';
import Video from './views/Video';
import Header from './views/Header';

import '@vime/core/themes/default.css';
import '@vime/core/themes/light.css';

const config = require('./config.json')

class App extends Component {
  render() {
    return (
        <main className="container">
        <Header/>
        <Router>
          <Switch>
            <Route exact path="/">
              <Gallery config={config}/>
            </Route>
            <Route path="/video">
              <Video config={config}/>
            </Route>
          </Switch>
        </Router>
        </main>
    );
  }
}

export default App;
