import React, { Component } from "react"
import './Header.css'

class Header extends Component {
    render() {
        return (
            <header className="Header" style={{
                display: 'flex',
                justifContent: 'space-between',
                alignItems: 'center',
                backgroundColor: "black",
                minHeight: 64,
            }}>
                <a href="/" className="Header noselect">STAY HOME BEAUTY</a>
            </header>
        )
    }
}


export default Header;